import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { TbColumnsOff } from "react-icons/tb";
import { IoTrashOutline } from "react-icons/io5";
import { LuPenLine } from "react-icons/lu";
import { LuFileOutput } from "react-icons/lu";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbArrowLeftToArc } from "react-icons/tb";
import { TbArrowRightToArc } from "react-icons/tb";
import { GoSearch } from "react-icons/go";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import {
  Box,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { BASE_URL } from '../../url/base';
import "./List.css";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const List = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isColumnChooserOpen, setIsColumnChooserOpen] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState(
    columns.reduce((acc, col) => ({ ...acc, [col.accessor]: true }), {})
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [newRow, setNewRow] = useState(
    columns.reduce((acc, col) => ({ ...acc, [col.accessor]: "" }), {})
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      const response = await fetch(
        `${BASE_URL}srecord/${tab}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      
      // Sort columns (moving name/label to start)
      const sortedColumns = data.columns.sort((a, b) => {
        if (a.name === "name" || a.name === "label" || a.name === "username") return -1;
        return 1;
      });

      // Set columns in the state
      setColumns(
        sortedColumns.map((col) => ({
          accessor: col.name,
          header: col.label,
          visible: col.name === "id" ? false : true, // Hide 'id' column by default
        }))
      );

      setColumnVisibility(
        sortedColumns.reduce((acc, col) => ({ ...acc, [col.name]: col.name !== "id" }), {})
      );

      setTableData(data.data || []);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tab]);

  // Handle screen resizing for responsive column visibility
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getSizeQualifier = (width) => {
    if (width <= 420) return 1;
    if (width <= 768) return 2;
    if (width <= 992) return 4;
    return columns.length;
  };

  const visibleColumnCount = getSizeQualifier(screenWidth);
  const visibleColumns = columns.slice(0, visibleColumnCount);
  const hasHiddenColumns = visibleColumns.length < columns.length;

  const filteredData = tableData.filter((row) =>
    Object.values(row)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); 
  };

  const toggleRowSelection = (rowId) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowId)
        ? prevSelected.filter((id) => id !== rowId)
        : [...prevSelected, rowId]
    );
  };

  const handleSelectAllChange = () => {
    if (currentRows.length > 0 && selectedRows.length === currentRows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentRows.map((row) => row.id));
    }
  };

  const toggleColumnVisibility = (accessor) => {
    setColumnVisibility((prev) => ({ ...prev, [accessor]: !prev[accessor] }));
  };

  const handleSave = (rowId) => {
    setTableData((prevData) =>
      prevData.map((row) => (row.id === rowId ? { ...row, ...newRow } : row))
    );
    setIsEditing(null);
  };

  const handleDelete = (rowId) =>
    setTableData((prevData) => prevData.filter((row) => row.id !== rowId));

  const handleAddNew = () => {
    setTableData((prevData) => [...prevData, newRow]);
    setNewRow(
      columns.reduce((acc, col) => ({ ...acc, [col.accessor]: "" }), {})
    );
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prevExpanded) =>
      prevExpanded.includes(rowId)
        ? prevExpanded.filter((id) => id !== rowId)
        : [...prevExpanded, rowId]
    );
  };

  return (
    <div className="maindiv">
      <div className="table-container">
        {/* Top bar with search and column chooser */}
        <div className="table-header">
          {/* Label */}
          <div className="label-div">
            <label className="label">{tab}</label>
          </div>
          {/* Button for dropdown menu on small screens */}
          <div className="opr-div">
            <button onClick={handleAddNew} className="add-btn">
              <LuPenLine style={{ fontSize: "20px" }} />
              Add New{" "}
            </button>
            <div className="export-pdf-excel">
              <IconButton className="export-pdf-excel-btn">
                <LuFileOutput style={{ fontSize: "20px" }} />
                <MdKeyboardArrowDown />
              </IconButton>
              <div className="export-pdf-excel-dropdown">
                {columns.map((column) => (
                  <div key={column.accessor}>
                    <input
                      type="checkbox"
                      checked={columnVisibility[column.accessor]}
                      onChange={() => toggleColumnVisibility(column.accessor)}
                    />
                    <label>{column.header}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="separator" />
            <div className="column-chooser">
              <IconButton
                onClick={() => setIsColumnChooserOpen(true)}
                className="column-chooser-btn"
              >
                <TbColumnsOff style={{ fontSize: "22px" }} />
              </IconButton>
              <div className="column-chooser-dropdown">
                <Modal
                  open={isColumnChooserOpen}
                  onClose={() => setIsColumnChooserOpen(false)}
                >
                  <Box className="column-chooser-modal">
                    <Typography variant="h6">Hide Columns</Typography>
                    {columns.map((column) => (
                      <div key={column.accessor} className="column-BTN">
                        <input
                          type="checkbox"
                          className="custom-checkbox2"
                          checked={columnVisibility[column.accessor]}
                          onChange={() =>
                            toggleColumnVisibility(column.accessor)
                          }
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                        <label>{column.header}</label>
                      </div>
                    ))}
                  </Box>
                </Modal>
              </div>
            </div>
            <div className="search-container">
            <GoSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-boxx"
              />
            </div>
          </div>
        </div>
        {/* Table structure */}
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th className="text-select">
                  <input
                    type="checkbox"
                    className="custom-checkbox1"
                    onChange={handleSelectAllChange}
                    checked={
                      currentRows.length > 0 &&
                      currentRows.every((row) => selectedRows.includes(row.id))
                    }
                  />
                </th>
                {visibleColumns.map((column) => {
                  if (column.accessor === "label"||column.accessor === "name" ||column.accessor === "username") {
                    return <th key={column.accessor}>{column.header}</th>;
                  }
                  return columnVisibility[column.accessor] ? (
                    <th key={column.accessor}>{column.header}</th>
                  ) : null;
                })}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentRows.length > 0 ? (
                currentRows.map((row) => (
                  <React.Fragment key={row.id}>
                    <tr
                      className={
                        selectedRows.includes(row.id) ? "selected" : ""
                      }
                      style={{
                        backgroundColor: selectedRows.includes(row.id)
                          ? "#03a9f412" // Inline style for the selected row
                          : "transparent",
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          className="custom-checkbox1"
                          checked={selectedRows.includes(row.id)}
                          onChange={() => toggleRowSelection(row.id)}
                        />
                      </td>
                      {visibleColumns.map(
                        (column) =>
                          columnVisibility[column.accessor] && (
                            <td key={column.accessor}>
                              {column.accessor === "label"||column.accessor === "name" ||column.accessor === "username" ? (
                                <Link
                                  to={`/detail/${row.id}/${row.name ||row.label ||row.username}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "#0f6cbd",
                                  }}
                                  onMouseEnter={(e) =>
                                    (e.target.style.color = "#479ef5")
                                  }
                                  onMouseLeave={(e) =>
                                    (e.target.style.color = "#1a73e8")
                                  }
                                >
                                  {row.name ||row.label ||row.username}
                                </Link>
                              ) : (
                                row[column.accessor]
                              )}
                            </td>
                          )
                      )}
                      <td className="text-right">
                        {hasHiddenColumns && (
                          <IconButton
                            onClick={() => toggleRowExpansion(row.id)}
                            style={{ fontSize: "12px", color: "#0f6cbd " }}
                          >
                            {expandedRows.includes(row.id) ? (
                              <FaChevronDown />
                            ) : (
                              <FaChevronRight />
                            )}
                          </IconButton>
                        )}
                        <IconButton onClick={() => handleDelete(row.id)}>
                          <IoTrashOutline
                            style={{ fontSize: "20px", color: "#0f6cbd " }}
                          />
                        </IconButton>
                      </td>
                    </tr>
                    {expandedRows.includes(row.id) && hasHiddenColumns && (
                      <tr>
                        <td colSpan={visibleColumns.length + 2}>
                          <div className="expanded-row">
                            {columns
                              .filter((col) => !visibleColumns.includes(col))
                              .map((col) => (
                                <div
                                  key={col.accessor}
                                  className="expanded-cell"
                                >
                                  <strong>{col.header}:</strong>{" "}
                                  {row[col.accessor]}
                                </div>
                              ))}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length + 1} className="no-data">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination Controls */}
        <div className="table-footer">
          <div className="pagination">
            <div className="select-container">
              <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                {[10, 15, 20].map((size) => (
                  <option key={size} value={size}>
                    {size} rows
                  </option>
                ))}
              </select>
            </div>
            <div className="pg-2-container">
              <div className="status">
                Page {currentPage} of {totalPages} ( {filteredData.length}{" "}
                items)
              </div>
              <IconButton
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="pgn-btn"
              >
                <TbArrowLeftToArc style={{ fontSize: "20px" }} />
              </IconButton>
              <IconButton
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="pgn-btn"
              >
                <TbArrowRightToArc style={{ fontSize: "20px" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
