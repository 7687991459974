// src/components/ThemeTransition.js
import React from 'react';
import './ThemeTransition.css'; // Import CSS for animations
// import logo512 from './logo512.png';
import { BiSolidColor } from "react-icons/bi";

const ThemeTransition = ({ onAnimationComplete }) => {
  return (
    <div className="theme-transition" onAnimationEnd={onAnimationComplete}>
                <BiSolidColor style={{ fontSize: "60px", color:"var(--heading-color)" }} className="roller-brush" />
      {/* <img src={logo512} alt="Paint Roller" className="roller-brush" /> */}
      <div className="loading-bar">
        <div className="dotted-loader"></div>
      </div>
    </div>
  );
};

export default ThemeTransition;
