import React from 'react';
import './Home.css';  // Optional: Create a CSS file for styling the landing page if needed

const Home = () => {
    const handleClick = () => {
        window.location.href = 'https://ealamgroup.pk/';
      };
  return (
    <React.Fragment>
    <div className={'content-block'}>
    <div className="home-container">
      <h1 className="welcome-title">Welcome to eAlam Group</h1>
            <p className="welcome-message">
    Welcome to eAlam Group, where excellence meets innovation! We are dedicated to providing cutting-edge solutions tailored to your unique business needs. Our team excels in delivering high-quality, innovative services designed to propel your success and achieve your highest goals.
  </p>
            <button className="explore-button" onClick={handleClick}>Explore More</button>
            </div>
            </div>
            </React.Fragment>
  );
};

export default Home;
