import React from 'react';
import './ResetPasswordForm.css';
const ResetPasswordForm = () => {
  return (
    <div className="Reset-container">
    <div className="Reset-container-wrap">
      <h2 className="Reset-heading">Reset Password</h2>

    

      <div style={{ fontSize: "14px", color:"#242424b3", marginBottom:"30px", marginTop:"15px", textAlign:"center"}} className="Reset-terms">
          <label>
          Please enter the email address that you used to register, and we will send you a link to reset your password via email.
        </label>
      </div>

      <div className="Reset-input-container">
          <input
            type="text"
            placeholder="user@gmail.com"
            className="Reset-input"
          />
        </div>
        <button className="Reset-button">Reset my password</button>

        <label style={{ fontSize: "13px", color:"#0f6cbd", marginBottom:"20px", marginTop:"20px" }} className="Reset-link-label">
        Return to 
        <a href="#" className="Reset-link">
          Sign in
        </a>
          </label>
    </div>
    </div>
  );
};

export default ResetPasswordForm;
