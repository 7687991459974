import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import "./Sidebar.css";
import { BASE_URL } from "../url/base.js";
import { getIconComponent } from "./getIconComponent.js";

// Utility function to truncate text if it exceeds a certain length
const truncateText = (text, length = 18) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

// Main TreeItem component for the main sidebar items
function TreeItem({
  label,
  icon,
  onClick,
  hasChildren = false,
  isOpen,
  toggleOpen,
}) {
  return (
    <div className="tree-item">
      <div onClick={onClick || toggleOpen} className="t-item">
        {icon && <div className="icon">{icon}</div>}
        <div className="text">{truncateText(label)}</div>
        {hasChildren && (
          <div className="arrow">
            {isOpen ? <FaChevronDown /> : <FaChevronRight />}
          </div>
        )}
      </div>
    </div>
  );
}

// Custom SubItem component for sub-items
function SubItem({ label, onClick }) {
  return (
    <div className="sub-item" onClick={onClick}>
      {truncateText(label)}
    </div>
  );
}

function Sidebar({ open, setSelectedContent }) {
  const [dynamicItems, setDynamicItems] = useState([]);
  const [openSections, setOpenSections] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Toggle section open/close
  const toggleSection = (index) => {
    setOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Close all sub-items when the sidebar collapses
  useEffect(() => {
    if (!open) {
      setOpenSections({});
    }
  }, [open]);

  // Fetch data and load icons dynamically for the sidebar items
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${BASE_URL}fetch-app-data`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          if (response.status === 403) {
            throw new Error("Permission denied. You don't have access.");
          } else {
            throw new Error(`Error fetching data: ${response.statusText}`);
          }
        }

        const data = await response.json();
        const items = await Promise.all(
          data.data.map(async (item, index) => {
            const mainIcon = await getIconComponent(item.icon);
            const subItems = item.apptab.map((subItem) => ({
              text: subItem.label,
              name: subItem.name,
            }));

            return {
              text: item.name,
              icon: mainIcon,
              isOpen: openSections[index],
              toggleOpen: () => toggleSection(index),
              items: subItems,
            };
          })
        );

        setDynamicItems(items);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [openSections]);

  const handleSubItemClick = (subItemName) => {
    // Navigate to List_ui with the tab parameter
    navigate(`/List_ui?tab=${subItemName}`);

    setSelectedContent(<div>Selected Tab: {subItemName}</div>);
  };

  if (loading) {
    return <div>Loading sidebar...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className={`sidebar ${open ? "expanded" : "collapsed"}`}>
        {dynamicItems.map((mainItem, index) => (
          <div key={index}>
            <TreeItem
              label={mainItem.text}
              icon={mainItem.icon}
              hasChildren={!!mainItem.items.length}
              isOpen={mainItem.isOpen}
              toggleOpen={mainItem.toggleOpen}
            />

            {mainItem.isOpen && mainItem.items && (
              <div className="sub">
                {mainItem.items.map((subItem, subIndex) => (
                  <SubItem
                    key={subIndex}
                    label={subItem.text}
                    onClick={() => handleSubItemClick(subItem.name)}
                  />
                ))}
              </div>
            )}
            <div className="separater" />
          </div>
        ))}
      </div>
    </>
  );
}

export default Sidebar;
