// getIconComponent.js
import React from "react";
import { IoTrashOutline } from "react-icons/io5";
import { TbColumnsOff } from "react-icons/tb";
import { LuFileOutput, LuPenLine } from "react-icons/lu";
import { HiOutlineInboxIn } from "react-icons/hi";
import { AiOutlineSetting } from "react-icons/ai";
import { FaChartBar } from "react-icons/fa";

const icons = {
  TbColumnsOff: <TbColumnsOff />,
  LuFileOutput: <LuFileOutput />,
  IoTrashOutline: <IoTrashOutline />,
  LuPenLine: <LuPenLine />,
  FaChartBar: <FaChartBar />,
  HiOutlineInboxIn: <HiOutlineInboxIn />,
  AiOutlineSetting: <AiOutlineSetting />
};

export async function getIconComponent(iconName) {
  return icons[iconName] || null;
}
