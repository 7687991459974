// Detail.js
import React from 'react';
import { useParams } from 'react-router-dom';

const Detail = () => {
  const { id, label } = useParams();

  return (
    <div>
      <h2>Detail for ID: {id}</h2>
      <p>Label: {label}</p>
      {/* You can add more details here based on your requirements */}
    </div>
  );
};

export default Detail;





