import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { ImFacebook2 } from "react-icons/im";
import { Snackbar, Alert, CircularProgress } from '@mui/material';  // Import MUI components
import './SignInForm.css';
import { signIn } from '../../api/auth'; // Import the signIn function

const SignInForm = ({ setIsAuthenticated }) => {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading
  const [openSnackbar, setOpenSnackbar] = useState(false); // Control snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    setLoading(true); // Set loading to true
    const response = await signIn(username, password); // Call the login API
    setLoading(false); // Set loading to false once the request completes

    if (response.isOk) {
      setIsAuthenticated(true); // Set authentication to true
      setSnackbarSeverity('success');
      setSnackbarMessage('Login successful!');
      setOpenSnackbar(true);
      setTimeout(() => navigate('/'), 1000); // Redirect to main layout after showing success
    } else {
      setSnackbarSeverity('error');
      setSnackbarMessage(response.message || 'Invalid credentials');
      setOpenSnackbar(true);
    }
  };

  const handleCreateAccount = () => {
    navigate('/create'); // Navigate to Create Account page
  };

  const handleForgotPassword = () => {
    navigate('/reset'); // Navigate to Reset Password page
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Close the snackbar
  };

  return (
    <div className="signin-container">
      <div className="signin-container-wrap">
        <h2 className="signin-heading">Sign In</h2>

        <div className="input-container">
          <input
            type="text"
            placeholder="Name"
            className="signin-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="signin-input password-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className={`password-toggle ${showPassword ? "active" : ""}`}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <VscEyeClosed /> : <VscEye />}
          </span>
        </div>

        <div className="signin-rememberMe">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={rememberMe}
            onChange={handleCheckboxChange}
          />
          <label>Remember Me:</label>
        </div>

        {/* Display loading spinner or button based on loading state */}
        <button className="signin-button" onClick={handleLogin} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Sign In'}
        </button>

        <a href="#" className="signin-link" onClick={handleForgotPassword}>Forgot password?</a>

        <button className="signin-createAccountButton" onClick={handleCreateAccount}>Create Account</button>

        <p style={{ alignSelf: "center", color: "#242424", fontSize: "14px", marginBottom: "30px" }}>or</p>

        <button className="signin-oauthButton">
          <FcGoogle style={{ fontSize: "16px", marginRight: "6px" }} className="signin-icon" /> Login with Google
        </button>

        <button className="signin-oauthButton">
          <ImFacebook2 style={{ color: "#0f6cbd", fontSize: "14px", marginRight: "8px" }} className="signin-icon" /> Login with Facebook
        </button>
      </div>

      {/* Snackbar for success and error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignInForm;
