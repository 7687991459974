import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from "./layouts/MainLayout";
import SignInForm from './unauth/Login/SignInForm';
import SignUpForm from './unauth/Create/SignUpForm'
import ResetPasswordForm from './unauth/Reset/ResetPasswordForm'
import ThemeTransition from './layouts/ThemeTransition'; // Import the transition component
import './index.css';
import './themes/root.css';
import './themes/Pinkish-root.css';
import './themes/purple-root.css';
import './themes/LemonChiffon-root.css';
import './themes/Yellowish-root.css';
import './themes/orange-root.css';
import './themes/dark-greenish-root.css';
import './themes/cyan-teal-root.css';
import './themes/skybluish-root.css';
import './themes/gray-backwhite-root.css';
import './themes/brownish-root.css';
import './themes/maroonish-reddish-root.css';
import './themes/metallic1-root.css';
import './themes/metallic2-root.css';

function App() {
  const [theme, setTheme] = useState("metallic2");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleThemeChange = (selectedTheme) => {
    setIsTransitioning(true); // Start the transition
    setTimeout(() => {
      setTheme(selectedTheme);
      setIsTransitioning(false); // End the transition after the animation
    }, 1000); // Match this duration with your CSS animation duration
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const bodyElement = document.body;
    const themeClass = `${theme} ${isDarkMode ? `${theme}-dark` : ""}`;
    bodyElement.className = themeClass;
  }, [theme, isDarkMode]);

  return (
    <BrowserRouter>
      <div className={`app-container ${theme} ${isDarkMode ? `${theme}-dark` : ""}`}>
      {isTransitioning && <ThemeTransition onAnimationComplete={() => setIsTransitioning(false)} />}
        <Routes>
          <Route path="/login" element={<SignInForm setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/create" element={<SignUpForm/>} />
          <Route path="/reset" element={<ResetPasswordForm/>} />
          <Route
            path="/*"
            element={isAuthenticated ? (
              <MainLayout
                toggleSidebar={toggleSidebar}
                isSidebarOpen={sidebarOpen}
                onThemeChange={handleThemeChange}
                toggleDarkMode={toggleDarkMode}
                isDarkMode={isDarkMode}
                setIsAuthenticated={setIsAuthenticated} // Ensure it's passed here
              />
            ) : (
              <Navigate to="/login" />
            )}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
