import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./Header"; 
import Sidebar from "./Sidebar"; 
import Home from "../pages/Home/Home"; 
import List from "../pages/List_ui/List_ui"; 
import Detail from "../pages/Detail_ui/Detail"; 

const MainLayout = ({ toggleSidebar, isSidebarOpen, onThemeChange, toggleDarkMode, isDarkMode, setIsAuthenticated }) => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="main-container">
      <Header 
        toggleSidebar={toggleSidebar}
        onThemeChange={onThemeChange}
        toggleDarkMode={toggleDarkMode}
        isDarkMode={isDarkMode}
        setIsAuthenticated={setIsAuthenticated} // Pass setIsAuthenticated to Header
      />
      <div className={`content-container ${isMobile ? "mobile-view" : ""}`}>
        <Sidebar open={isSidebarOpen} setSelectedContent={setSelectedContent} />
        <main className="content">
        <Routes>
            <Route path="/" element={<Home />} />  {/* Default route for the home page */}
            <Route path="/List_ui" element={<List />} />
            <Route path="/Detail" element={<Detail />} />
            <Route path="*" element={<Navigate to="/" />} />  {/* Redirect any unknown paths to Home */}
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default MainLayout;
