import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { ImFacebook2 } from "react-icons/im";
import { VscEye, VscEyeClosed } from "react-icons/vsc"; // Importing eye icons
import "./SignUpForm.css"; // Import the CSS file

const SignUpForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmedPasswordVisibility = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  return (
    <div className="signup-container">
      <div className="signup-container-wrap">
        <h2 className="signup-heading">Sign Up</h2>

        {/* Wrap both inputs in a container div */}
        <div className="signup-input-container">
          <input
            type="text" 
            placeholder="Username" 
            className="signup-input" 
          />

          <input
            type="text"
            placeholder="user@gmail.com"
            className="signup-input"
          />

          <input
            type="number"
            placeholder="Phone"
            className="signup-input"
          />

          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="signup-input password-input"
          />
          <span
            className={`create-password-toggle ${showPassword ? "active" : ""}`}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <VscEyeClosed /> : <VscEye />}
          </span>

          <input
            type={showConfirmedPassword ? "text" : "ConfirmedPassword"}
            placeholder="Confirmed Password"
            className="signup-input password-input"
          />
          <span
            className={`Confirmed-Password-toggle ${showConfirmedPassword ? "active" : ""}`}
            onClick={toggleConfirmedPasswordVisibility}
          >
            {showConfirmedPassword ? <VscEyeClosed /> : <VscEye />}
          </span>
        </div>
        <div style={{ fontSize: "13px", color:"#242424b3", marginBottom:"20px"}} className="signup-terms">
          <label>
            By creating an account, you agree to the{" "}
            <a href="#" className="privacy-link">
              {" "}
              Terms of Service{" "}
            </a>
            and
            <a href="#" className="privacy-link">
              {" "}
              Privacy Policy
            </a>
            .
          </label>
        </div>

        <button className="signup-button">Create a new account</button>

        <label style={{ fontSize: "13px", color:"#0f6cbd", marginBottom:"20px", marginTop:"20px" }} className="signup-link-label">
        Have an account?
        <a href="#" className="signup-link">
          Sign in
        </a>
          </label>
        
        <div className="separatar" />

        <p style={{ alignSelf: "center" }}>or</p>

        <button className="signup-oauthButton">
          <FcGoogle
            style={{ fontSize: "16px", marginRight: "6px" }}
            className="signup-icon"
          />{" "}
          Login with Google
        </button>

        <button className="signup-oauthButton">
          <ImFacebook2
            style={{ color: "#0f6cbd", fontSize: "14px", marginRight: "8px" }}
            className="signup-icon"
          />{" "}
          Login with Facebook
        </button>
      </div>
    </div>
  );
};

export default SignUpForm;
